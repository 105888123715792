import { Controller } from '@hotwired/stimulus';
import { formatTime } from '../human_time_formatter';

export default class extends Controller {
  static values = {
    measuredAt: String,
    elapsedTime: Number,
    isRunning: Boolean
  };

  static targets = ['outlet'];

  connect () {
    const measuredAt = new Date(this.measuredAtValue);
    const alreadyElapsedTime = this.elapsedTimeValue;

    this.update(alreadyElapsedTime, measuredAt);

    if (this.isRunningValue) {
      this.interval = setInterval(() => {
        this.update(alreadyElapsedTime, measuredAt);
      }, 1000);
    }
  }

  update (alreadyElapsedTime, measuredAt) {
    const elapsedTime = alreadyElapsedTime + ((new Date() - measuredAt) / 1000);

    this.outletTarget.innerText = formatTime(Math.max(elapsedTime, 0));
  }

  disconnect() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}
