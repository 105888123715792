import { Controller } from '@hotwired/stimulus';
import { formatTimeHoursMinutes } from '../human_time_formatter';

export default class extends Controller {
   static values = {
    measuredAt: String,
    elapsedTime: Number,
    isRunning: Boolean,
  };

  connect () {
    if (!this.isRunningValue) {
      return
    }
    const measuredAt = new Date(this.measuredAtValue);
    const alreadyElapsedTime = this.elapsedTimeValue;

    this.interval = setInterval(() => {
      this.updateTime(alreadyElapsedTime, measuredAt);
    }, 10 * 1000);

    this.updateTime(alreadyElapsedTime, measuredAt);
  }

  updateTime(alreadyElapsedTime, measuredAt) {
    const elapsedTime = alreadyElapsedTime + ((new Date() - measuredAt) / 1000);
    document.title = `[${formatTimeHoursMinutes(Math.max(elapsedTime, 0))}] Arbeitszeiterfassung`;
  }

  disconnect () {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}
