import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    showAt: String
  };

  static targets = ['outlet'];

  connect () {
    const date = new Date(this.showAtValue);
    const now = new Date();
    const timeToShow = date - now;

    this.timeout = setTimeout(
      () => {
        this.outletTarget.classList.remove('hidden');
      }, timeToShow
    );
  }

  disconnect () {
    clearInterval(this.timeout);
  }
}
