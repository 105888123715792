import { Controller } from '@hotwired/stimulus';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

export default class extends Controller {
  static values = {
    url: String
  };

  static targets = ['outlet'];

  connect () {
    fetch(this.urlValue)
      .then(response => response.json())
      .then((config) => {
        new Chart(this.outletTarget, config); // eslint-disable-line no-new
      });
  }
}
