// Entry point for the build script in your package.json
import { Application } from '@hotwired/stimulus';
import UpdatingTimerController from './controllers/updating_timer_controller';
import TimedAlertController from './controllers/timed_alert_controller';
import ChartController from './controllers/chart_controller';
import HtmlTitleController from './controllers/html_title_controller';
import * as Turbo from "@hotwired/turbo"

window.Stimulus = Application.start();

window.Stimulus.register('updating-timer', UpdatingTimerController);
window.Stimulus.register('timed-alert', TimedAlertController);
window.Stimulus.register('html-title', HtmlTitleController);
window.Stimulus.register('chart', ChartController);
