export const formatTime = (elapsedTime) => {
  const hours = Math.floor(elapsedTime / 3600);
  const minutes = Math.floor(((elapsedTime % 3600) / 60));
  const seconds = Math.floor(elapsedTime % 60);

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};

export const formatTimeHoursMinutes = (elapsedTime) => {
  const hours = Math.floor(elapsedTime / 3600);
  const minutes = Math.floor(((elapsedTime % 3600) / 60));
  const seconds = Math.floor(elapsedTime % 60);

  return `${String(hours).padStart(1, '0')}:${String(minutes).padStart(2, '0')}`;
};
